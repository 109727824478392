import React from 'react';

export default function Svg({id, className = '', onClick = () => {}, style = {}}) {
    if (id === undefined) {
        return null;
        // throw new Error('FilterButton requires filters prop to be set');
    }

    return (
        <svg className={className} onClick={onClick} style={style}>
            <use href={`#${id}`} xlink:href={`#${id}`}></use>
        </svg>
    );
}
